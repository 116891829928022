import Vue from 'vue'

const camelCase = string => string.replace(/\W+(.)/g, (match, chr) => chr.toUpperCase())
const capitalize = string => string.replace(/^\w/, c => c.toUpperCase())

const componentFilenames = [
  'VeeAutocomplete', 'VeeCombobox', 'VeeFileInput', 'VeeSelect', 'VeeTextarea', 'VeeTextField',
]

componentFilenames.forEach(filename => {
  // eslint-disable-next-line import/no-dynamic-require
  const componentConfig = require(`@/components/veeInputs/${filename}`)
  const componentName = capitalize(
    camelCase(filename.split('/').pop().replace(/\.\w+$/, '')),
  )

  Vue.component(
    componentName,
    componentConfig.default || componentConfig,
  )
})
