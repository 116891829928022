<template>
  <v-menu offset-y left nudge-bottom="14" min-width="230" content-class="user-profile-menu-content">
    <template v-slot:activator="{ on, attrs }">
      <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
        <v-avatar size="40px" v-bind="attrs" v-on="on">
          <v-img :src="require('@/assets/images/avatars/male.svg')" />
        </v-avatar>
      </v-badge>
    </template>
    <v-list v-if="auth.user">
      <div class="pb-3 pt-2">
        <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
          <v-avatar size="40px">
            <v-img :src="require('@/assets/images/avatars/male.svg')" />
          </v-avatar>
        </v-badge>

        <div class="d-inline-flex flex-column justify-center ms-3" style="vertical-align: middle">
          <span class="username text--primary font-weight-semibold mb-n1 text-truncate">
            {{ auth.user.email }}
          </span>
          <small class="text--disabled text-capitalize">
            {{ auth.jwtData?.claims?.custom_claims?.join(', ') }}
          </small>
        </div>
      </div>

      <v-divider />

      <v-list-item link @click="onLogout">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Logout
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mdiLogoutVariant } from '@mdi/js'
import useAuth from '@/composables/useAuth'
import { useRouter } from '@/utils/index'

export default {
  setup() {
    const { router } = useRouter()
    const { state: auth } = useAuth()

    const onLogout = () => {
      router.push({ name: 'logout' })
    }

    return {
      auth,

      onLogout,

      icons: {
        mdiLogoutVariant,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }

  .username {
    vertical-align: middle;
    width: calc(100% - 16px);
    margin-right: 16px;
  }
}
</style>
