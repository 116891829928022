<template>
  <component :is="resolveLayout">
    <router-view></router-view>

    <v-snackbar v-model="snackbar.visible" :timeout="7000" :color="snackbar.color">
      <span v-html="snackbar.text"></span>

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.visible = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-overlay :value="imageLightBox.src" @click.native="closeOverlay()">
      <v-img :src="imageLightBox.src ? imageLightBox.src : ''" contain />
    </v-overlay>
  </component>
</template>

<script>
import { computed } from 'vue'
import { useRouter } from '@/utils'
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'
import useImageLightBox from '@/composables/imageLightBox'
import useSnackbar from '@/composables/snackbar'

export default {
  components: {
    LayoutBlank,
    LayoutContent,
  },

  setup() {
    const { route } = useRouter()
    const { state: imageLightBox, closeOverlay } = useImageLightBox()
    const { state: snackbar } = useSnackbar()

    const resolveLayout = computed(() => {
      if (route.value.name === null) {
        return null
      }

      if (route.value.meta.layout === 'blank') {
        return 'layout-blank'
      }

      return 'layout-content'
    })

    return {
      resolveLayout,

      imageLightBox,
      closeOverlay,

      snackbar,
    }
  },
}
</script>
