import {
  double, integer, max, oneOf, required,
} from 'vee-validate/dist/rules'
import { extend } from 'vee-validate'

extend('integer', {
  ...integer,
  message: 'This field must be an integer',
})

extend('double', {
  ...double,
  message: 'This field must be a float',
})

extend('max', {
  ...max,
  message: 'This field must be {length} characters or less',
})

extend('oneOf', {
  ...oneOf,
  message: 'Invalid value',
})

extend('required', {
  ...required,
  message: 'Required',
})

extend('url', {
  validate: value => {
    try {
      const url = new URL(value)

      return url.pathname.length && (['http:', 'https:'].includes(url.protocol))
    } catch {
      return false
    }
  },
  message: 'Invalid url',
})

extend('imageFile', {
  validate: value => {
    try {
      return value.type.startsWith('image/')
    } catch {
      return false
    }
  },
  message: 'Not an image file',
})

extend('videoFile', {
  validate: value => {
    try {
      return value.type.startsWith('video/')
    } catch {
      return false
    }
  },
  message: 'Not a video file',
})
