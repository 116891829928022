import { signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { firebaseAuth } from '@/firebase'
import { reactive } from 'vue'

const state = reactive({
  user: null,
  jwtData: null,
})

export default function useAuth() {
  const login = async (email, password) => signInWithEmailAndPassword(firebaseAuth, email, password)
  const logout = async () => signOut(firebaseAuth)

  const hasRole = roles => {
    const userClaims = state.jwtData?.claims?.custom_claims || []
    roles = Array.isArray(roles) ? roles : [roles]

    return roles.some(role => userClaims.includes(role))
  }

  return {
    state,

    login,
    logout,
    hasRole,
  }
}
