import { reactive } from 'vue'

const state = reactive({
  src: false,
})

export default function useImageLightBox() {
  const openOverlay = src => {
    state.src = src
  }

  const closeOverlay = () => {
    state.src = false
  }

  return {
    state,

    openOverlay,
    closeOverlay,
  }
}
