import Vue from 'vue'
import '@/styles/styles.scss'
import { firebaseAuth } from '@/firebase'
import axios from 'axios'
import useAuth from '@/composables/useAuth'
import App from './App.vue'
import './plugins/vee-validate'
import './plugins/autoload-components'
import vuetify from './plugins/vuetify'
import router from './router'

Vue.config.productionTip = false

let vm = null
const { state: auth } = useAuth()

function setupAxios() {
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL

  axios.interceptors.request.use(config => {
    auth.user
      ? config.headers.Authorization = `Bearer ${auth.user.accessToken}`
      : delete config.headers.Authorization

    return config
  }, error => Promise.reject(error))
}

setupAxios()

// eslint-disable-next-line consistent-return
firebaseAuth.onIdTokenChanged(async user => {
  if (vm && auth.user && !user) {
    auth.user = null
    auth.jwtData = null

    if (router.currentRoute.name !== 'login' && router.currentRoute.name !== 'logout') {
      return router.push({ name: 'login' })
    }
  }

  auth.user = user
  auth.jwtData = await user?.getIdTokenResult()

  if (!vm) {
    vm = new Vue({
      router,
      vuetify,
      render: h => h(App),
    }).$mount('#app')
  }
})
