import { reactive } from 'vue'

const state = reactive({
  visible: false,
  text: '',
  color: '',
})

export const SnackbarType = Object.freeze({
  DEFAULT: '',
  INFO: 'info',
  SUCCESS: 'success',
  ERROR: 'error',
})

export default function useSnackbar() {
  const showSnackbar = (text, color = SnackbarType.default) => {
    state.visible = true
    state.text = text
    state.color = color
  }

  const showErrorSnackbar = text => {
    state.visible = true
    state.text = text
    state.color = SnackbarType.error
  }

  const showInfoSnackbar = text => {
    state.visible = true
    state.text = text
    state.color = SnackbarType.info
  }

  const showSuccessSnackbar = text => {
    state.visible = true
    state.text = text
    state.color = SnackbarType.success
  }

  return {
    state,

    showSnackbar,
    showErrorSnackbar,
    showInfoSnackbar,
    showSuccessSnackbar,
  }
}
